import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_DEV,
});

export const token = JSON.parse(localStorage.getItem("persist:root"))?.auth
  ? JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.auth)?.user
      ?.token
  : null;
// console.log(token,"token..");
// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
  // Do something before request is sent
  // config.params = config.params || {}
  // config.params['auth'] = 'iazadur'
  // console.log(config);
  // console.log(config.headers);
  config.headers["Authorization"] = token ? "Bearer " + token : null;
  // config.headers['Content-Type'] = token ? 'multipart/form-data' : 'application/json'
  return config;
});

export default axiosInstance;

import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import useTheme from "./hooks/useTheme";
import httpReq from "./services/http.service";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "./services/utils/localStorage";
import { PageView } from "./tracking/facebook";
import {
  LoaderEight,
  LoaderFive,
  LoaderFour,
  LoaderNine,
  LoaderOne,
  LoaderSeven,
  LoaderSix,
  LoaderThree,
  LoaderTwo,
} from "./views/components/preLoader/PreLoader";
import MainLayout from "./views/Layout";
import Layout from "./views/main_theme/Layout";
import WrongUrl from "./views/WrongUrl";

ReactGA.initialize("G-X3YV7Q97C9");
AOS.init();

function App() {
  const { headerSetting } = useTheme();
  const [theme, setTheme] = useState(null);
  const [loader, setLoader] = useState(true);

  const [data, setData] = useState(null);

  const preloader = theme?.design?.preloader;

  useEffect(() => {
    PageView();
    if (headerSetting?.gtm) {
      TagManager.initialize({ gtmId: headerSetting?.gtm });
    }
  }, [headerSetting]);

  useEffect(() => {
    if (preloader) {
      saveToLocalStorage("loader", preloader);
    }
    const userData = getFromLocalStorage("loader");
    if (userData) {
      setData(userData);
    }
  }, [preloader]);

  const ebitans = window.location.host;
  const domain = window.location.host;

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await httpReq.post("getsubdomain/name", { name: domain });
        if (!data?.error) {
          setTheme(data);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        } else {
          setTheme(undefined);
          setLoader(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    const subdomain = domain.split(".")[0];

    if (subdomain === "www") {
      const domainMain = domain.slice(4, 100);
      window.location.replace("http://" + domainMain);
    } else {
      fetchData();
    }
  }, [domain]);

  if (theme === null && !data) {
    return <div className="bg-black h-screen w-full"></div>;
  }

  if (loader && (data || preloader)) {
    return (data || preloader) === "default" ? (
      <LoaderOne />
    ) : (data || preloader) === "one" ? (
      <LoaderOne />
    ) : (data || preloader) === "two" ? (
      <LoaderTwo />
    ) : (data || preloader) === "three" ? (
      <LoaderThree />
    ) : (data || preloader) === "four" ? (
      <LoaderFour />
    ) : (data || preloader) === "five" ? (
      <LoaderFive />
    ) : (data || preloader) === "six" ? (
      <LoaderSix />
    ) : (data || preloader) === "seven" ? (
      <LoaderSeven />
    ) : (data || preloader) === "eight" ? (
      <LoaderEight />
    ) : (data || preloader) === "nine" ? (
      <LoaderNine />
    ) : (
      <div className="bg-black h-screen w-full"></div>
    );
  }

  return (
    <>
      {(ebitans === "ebitans.com" ||
        ebitans === "localhost:3000" ||
        ebitans === "localhost:3001" ||
        ebitans === "localhost:4444" ||
        ebitans === "ebitans.store" ||
        ebitans === "ebitans.com.bd") && <Layout />}
      {theme && <MainLayout />}
      {theme === undefined &&
        ebitans !== "ebitans.com" &&
        ebitans !== "ebitans.com.bd" &&
        ebitans !== "www.ebitans.com.bd" &&
        ebitans !== "www.ebitans.com" &&
        ebitans !== "localhost:3000" && <WrongUrl />}
    </>
  );
}

export default App;

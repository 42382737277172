import axiosInstance from "./AxiosInstance";

const product_details = (store_id, product_id) => {
    return axiosInstance.post("product-details", {
        store_id,
        product_id
    })
};

const authService = {
    product_details,
   
};
export default authService;
import review from '../../../../../assets/images/review.png';
import darkLogo from '../../../../../assets/images/logo-dark.png';
import laptop from '../../../../../assets/images/laptop.png';
import plan from '../../../../../assets/images/plan.png';
import register from '../../../../../assets/images/register.png';
import world from '../../../../../assets/images/world.png';
import website from '../../../../../assets/images/website.png';
import store from '../../../../../assets/images/store.png';
import user4 from '../../../../../assets/images/Ellipse1.png';
import user3 from '../../../../../assets/images/Ellipse2.png';
import user2 from '../../../../../assets/images/Ellipse3.png';
import user1 from '../../../../../assets/images/Ellipse.png';
import fb from '../../../../../assets/images/fb.svg';
import insta from '../../../../../assets/images/insta.svg';
import whatsapp from '../../../../../assets/images/whatsapp.svg';
import youtube from '../../../../../assets/images/youtube.svg';
import fbGroup from '../../../../../assets/images/fbGroup.svg';
import googlePlay from '../../../../../assets/images/googlePlay.svg';
import appleStore from '../../../../../assets/images/appleStore.svg';
import location from '../../../../../assets/images/location.svg';
import phone from '../../../../../assets/images/phone.svg';
import mail from '../../../../../assets/images/mail.svg';
import whiteLogo from '../../../../../assets/images/whiteLogo.png';
import watch from '../../../../../assets/images/watch.png';
import phone1 from '../../../../../assets/images/phone.png';
import brandImage from '../../../../../assets/images/brand.png';
import Layer_1 from '../../../../../assets/images/Layer_1.png';
import Layer_2 from '../../../../../assets/images/Layer_2.png';
import Layer_3 from '../../../../../assets/images/Layer_3.png';
import Layer_4 from '../../../../../assets/images/Layer_4.png';
import Layer_5 from '../../../../../assets/images/Layer_5.png';
import Bayezid from '../../../../../assets/images/clients-logo/Bayezid tasnin.png';
import Kidz_Craze from '../../../../../assets/images/clients-logo/Kidz_Craze.png';
import comfy from '../../../../../assets/images/clients-logo/comfy Fashion.png';
import gurushop from '../../../../../assets/images/clients-logo/Guru shop BD.png';
import stylekit from '../../../../../assets/images/clients-logo/style kit.png';
import tas_logo from '../../../../../assets/images/clients-logo/Style Taz.png';
import wafashion from '../../../../../assets/images/clients-logo/wa fashion.png';
import inventory from '../../../../../assets/images/ims.png';
import mobile_app from '../../../../../assets/images/mobile_app_mobile.png';
import point from '../../../../../assets/images/pointofsale.png';
import social_media from '../../../../../assets/images/social_media_mobile.png';
import website_mobile from '../../../../../assets/images/website_mobile.png';
import round from '../../../../../assets/images/round.png';
import line from '../../../../../assets/images/line.png';
import web from '../../../../../assets/images/web.png';
import triangle from '../../../../../assets/images/triangle.png';
import line_white from '../../../../../assets/images/line_white.png';
import web_white from '../../../../../assets/images/web_white.png';
import triangle_white from '../../../../../assets/images/triangle_white.png';
import website_element from '../../../../../assets/images/website_element.png';
import arrow1 from '../../../../../assets/images/arrow.svg';
import arrowUp from '../../../../../assets/images/arrow-up.svg';
import arrow from '../../../../../assets/images/arrow.gif';
import tick from '../../../../../assets/images/tick.svg';
import close from '../../../../../assets/images/close.svg';
import businessman from '../../../../../assets/images/businessman.png';
import email from '../../../../../assets/images/contact/email.png';
import phone2 from '../../../../../assets/images/contact/phone1.png';
import contactLocation from '../../../../../assets/images/contact/location.png';
import react from '../../../../../assets/images/icons/react.png';
import hosting from '../../../../../assets/images/icons/hosting.png';
import inventory2 from '../../../../../assets/images/icons/inventory.png';
import http from '../../../../../assets/images/icons/http.png';
import subscription from '../../../../../assets/images/icons/subscription-2.png';
import easy from '../../../../../assets/images/icons/easy-installation.png';
import social1 from '../../../../../assets/images/social1.jpg';
import social2 from '../../../../../assets/images/social2.jpg';
import ecommerce from '../../../../../assets/images/videoWebsite.png';
import business from '../../../../../assets/images/business.png';
import pc from '../../../../../assets/images/pc.png';
import blogBanner from '../../../../../assets/images/blog-banner.webp';
import darkPse from '../../../../../assets/images/dark-pse.png';
import PseImgBanner from '../../../../../assets/images/PseBanner.webp';
import bkash from '../../../../../assets/images/partners/BKASH.svg';
import nagad from '../../../../../assets/images/partners/NOGOD.svg';
import amarpay from '../../../../../assets/images/partners/AAMAR PAY.svg';
import ecourier from '../../../../../assets/images/partners/ECOURIER.svg';
import bigBd from '../../../../../assets/images/partners/BIG BANGLADESH.svg';
import bylc from '../../../../../assets/images/partners/BYLC.svg';
import pathao from '../../../../../assets/images/partners/PATHAO.svg';
import redx from '../../../../../assets/images/partners/REDX.svg';
import ssl from '../../../../../assets/images/partners/SSLCOMMERZ.svg';
import registerBanner from '../../../../../assets/images/registerBanner.png';
import phoneEbitans from '../../../../../assets/images/phone ebitans.png';

const images = {
    darkLogo: darkLogo,
    laptop: laptop,
    review: review,
    plan: plan,
    register: register,
    world: world,
    website: website,
    store: store,
    user4: user4,
    user3: user3,
    user2: user2,
    user1: user1,
    fb: fb,
    insta: insta,
    whatsapp: whatsapp,
    youtube: youtube,
    fbGroup: fbGroup,
    googlePlay: googlePlay,
    appleStore: appleStore,
    location: location,
    phone: phone,
    mail: mail,
    whiteLogo: whiteLogo,
    watch: watch,
    phone1: phone1,
    brandImage: brandImage,
    Layer_1: Layer_1,
    Layer_2: Layer_2,
    Layer_3: Layer_3,
    Layer_4: Layer_4,
    Layer_5: Layer_5,
    Bayezid: Bayezid,
    Kidz_Craze: Kidz_Craze,
    comfy: comfy,
    gurushop: gurushop,
    stylekit: stylekit,
    tas_logo: tas_logo,
    wafashion: wafashion,
    inventory: inventory,
    mobile_app: mobile_app,
    point: point,
    social_media: social_media,
    website_mobile: website_mobile,
    round: round,
    line: line,
    web: web,
    triangle: triangle,
    line_white: line_white,
    web_white: web_white,
    triangle_white: triangle_white,
    website_element: website_element,
    arrow1: arrow1,
    arrowUp: arrowUp,
    arrow: arrow,
    close: close,
    tick: tick,
    businessman: businessman,
    email: email,
    phone2: phone2,
    contactLocation: contactLocation,
    react: react,
    hosting: hosting,
    inventory2: inventory2,
    http: http,
    subscription: subscription,
    easy: easy,
    social1: social1,
    social2: social2,
    ecommerce: ecommerce,
    business: business,
    pc: pc,
    blogBanner: blogBanner,
    darkPse: darkPse,
    PseImgBanner: PseImgBanner,
    ecourier: ecourier,
    bkash: bkash,
    nagad: nagad,
    amarpay: amarpay,
    ssl: ssl,
    redx: redx,
    pathao: pathao,
    bylc: bylc,
    bigBd: bigBd,
    registerBanner: registerBanner,
    phoneEbitans: phoneEbitans,
    // Add other images here...
};

export default images;


export const imgUrl = 'https://admin.ebitans.com/assets/images/setting/'
export const iconImg = 'https://admin.ebitans.com/assets/images/icon/'
export const catImg = 'https://admin.ebitans.com/assets/images/category/'
export const sliderImg = 'https://admin.ebitans.com/assets/images/slider/'
export const productImg = 'https://admin.ebitans.com/assets/images/product/'
export const bannerImg = 'https://admin.ebitans.com/assets/images/banner/'
export const testimonialImg = 'https://admin.ebitans.com/assets/images/testimonials/'
export const profileImg = 'https://admin.ebitans.com/assets/images/img/'
export const modalImg = 'https://admin.ebitans.com/assets/images/setting/'
export const themeImg = 'https://admin.ebitans.com/assets/images/template/'
export const blogImg = 'https://admin.ebitans.com/BlogImages/'
export const brandImg = 'https://admin.ebitans.com/assets/images/brand/'


// export const imgUrl = 'https://admin.ebitans.store/assets/images/setting/'
// export const iconImg = 'https://admin.ebitans.store/assets/images/icon/'
// export const catImg = 'https://admin.ebitans.store/assets/images/category/'
// export const sliderImg = 'https://admin.ebitans.store/assets/images/slider/'
// export const productImg = 'https://admin.ebitans.store/assets/images/product/'
// export const bannerImg = 'https://admin.ebitans.store/assets/images/banner/'
// export const testimonialImg = 'https://admin.ebitans.store/assets/images/testimonials/'
// export const profileImg = 'https://admin.ebitans.store/assets/images/img/'
// export const modalImg = 'https://admin.ebitans.store/assets/images/setting/'
// export const themeImg = 'https://admin.ebitans.store/assets/images/template/'


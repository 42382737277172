import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import productReducer from "./slices/productslice";
import siteSlice from "./slices/siteSlice";
import productslice from "./slices/product";

const rootReducer = combineReducers({
  auth: authReducer,
  message: messageReducer,
  cart: productReducer,
  site: siteSlice,
  product: productslice,
});
export default rootReducer;

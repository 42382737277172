
import { useEffect, useRef, useState } from 'react'
import { FaBars } from "react-icons/fa";
import { IoCloseCircleOutline } from 'react-icons/io5';
import { IoIosArrowDown } from "react-icons/io";
import styles from '../home.module.css';
import { ebitansColor } from '../../../../components/utils/EbitansColor';
import LanguageChanger from './LanguageChanger';
import { NavLink, useLocation } from 'react-router-dom';
import images from '../Image/Image.js';


const Header = () => {
    const [locale, setLocale] = useState("en")

    const handleChangeTwo =(newLocale)=>{
        setLocale(newLocale)
    }


    const [open, setOpen] = useState(false)
    const [openSub, setOpenSub] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const {pathname} = useLocation()
    const dropdownRef = useRef(null);
    const navbarRef = useRef(null);

    useEffect(() => {
        if (typeof window !== 'undefined' && window.innerWidth > 1024) {
            const handleClickOutside = (event) => {
                if (
                    navbarRef.current &&
                    !navbarRef.current.contains(event.target) &&
                    dropdownRef.current &&
                    !dropdownRef.current.contains(event.target)
                ) {
                    setOpen(false);
                    setOpenSub(false);
                }
            };
            if (open || openSub) {
                document.addEventListener('mousedown', handleClickOutside);
            } else {
                // Remove event listener when dropdown is closed
                document.removeEventListener('mousedown', handleClickOutside);
            }

            // Cleanup function to remove event listener on unmount
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
      
    }, [open || openSub]);

    const styleCss = `
    .nav-NavLink:not(.active):hover{
      color:#f1593a;
     }
    .active{
      color:#f1593a;
      font-weight: 700;
     }
     .slide-active-pse .swiper-slide-active {
      transition: 1s ease-in-out;
      scale: 2.5;
  }
  `
    return (
        <>
            <style>{styleCss}</style>
            <header className='bg-white lg:h-[85px] h-[50px] flex lg:px-24 items-center fixed w-full z-[10] shadow-lg'>
                <div className='w-full flex justify-between items-center container px-5 lg:px-10'>
                    <div>
                        <NavLink to="/"><img width={500} height={500} src={images?.darkLogo} alt="ebitansLogo" className='lg:h-12 h-7 w-auto' /></NavLink>
                    </div>
                    <div onClick={() => setOpenMenu(!openMenu)} className='lg:hidden'>
                        <FaBars className={`text-2xl text-[${ebitansColor}]`} />
                    </div>
                    <div ref={navbarRef} className="hidden lg:block">
                        <div className={`flex items-center gap-x-7`}>
                            <ul className='relative flex items-center gap-x-7 group'>
                                <NavLink to={`/`} className="nav-NavLink duration-300 ">
                                    <li onClick={() => { setOpenSub(false); setOpen(false) }} className={`${styles.archivo} ${(pathname === '/' || pathname === '/bn') ? 'active' : ''}`}>
                                        {locale === "en" ? "Home" : "হোম"}
                                    </li>
                                </NavLink>

                                <div className='relative'>
                                    <li onClick={() => { setOpenSub(!openSub); setOpen(false) }} className={`${styles.archivo} nav-NavLink flex items-center gap-2 lg:cursor-pointer duration-300`}>
                                        <span className={`${styles.archivo} hover:text-[#f1593a] duration-300 ${openSub ? "active" : ""}`}>{locale === "en" ? "Solutions" : "সমাধান"}</span>
                                        <IoIosArrowDown className='hover:text-[#f1593a] duration-300 ' />

                                    </li>
                                    {openSub && <div className='hidden lg:flex items-center gap-3 absolute -bottom-[95px] -left-[120px] drop-shadow-md w-max border-x border-y border-y-[#26161234]  border-x-[#694F49] bg-white px-10'>
                                        {subMenu_solutions?.map((sub, id) =>
                                            <div key={id} className='px-5'>
                                                <NavLink to={sub?.NavLink} onClick={() => { setOpenSub(false); setOpen(false) }} className={`nav-NavLink`}><li className={`${styles.archivo} ${(pathname === `/bn${sub?.NavLink}` || pathname === `${sub?.NavLink}`) ? 'active' : ''} py-5 list-disc`}>{locale === "en" ? sub?.name : sub?.nameBn}</li></NavLink>
                                            </div>
                                        )}
                                    </div>}
                                </div>

                                <NavLink to='/pricing' className="nav-NavLink duration-300 ">
                                    <li onClick={() => { setOpenSub(false); setOpen(false) }} className={`${styles.archivo} ${(pathname === '/pricing' || pathname === '/bn/pricing') ? 'active' : ''}`}>
                                        {locale === "en" ? "Pricing" : "মূল্য তালিকা"}
                                    </li>
                                </NavLink>

                                <div className='relative'>
                                    <li onClick={() => { setOpenSub(false); setOpen(!open) }} className={`${styles.archivo} nav-NavLink flex items-center gap-2 lg:cursor-pointer duration-300`}>
                                        <span className={`${styles.archivo} hover:text-[#f1593a] duration-300 ${open ? "active" : ""}`}>{locale === "en" ? "Resources" : "রিসোর্স"}</span>
                                        <IoIosArrowDown className='hover:text-[#f1593a] duration-300 ' />
                                    </li>

                                    {open && <div className='hidden lg:flex items-center gap-3 absolute  -bottom-[95px] -left-[190px] drop-shadow-md w-max border-x border-y border-y-[#26161234]  border-x-[#694F49] bg-white px-10'>
                                        {subMenu_resource?.map((sub, id) =>
                                            <div ref={dropdownRef} key={id} className='px-5'>
                                                {sub?.name === "Video" ? <NavLink to={'https://www.youtube.com/@ebitans/videos'} target="_blank"><li className={`nav-NavLink list-disc`}>{locale === "en" ? sub?.name : sub?.nameBn}</li></NavLink> : <NavLink to={sub?.NavLink} onClick={() => { setOpenSub(false); setOpen(false) }} className={`nav-NavLink`}><li className={`${styles.archivo} ${(pathname === `/bn${sub?.NavLink}` || pathname === `${sub?.NavLink}`) ? 'active' : ''} py-5 list-disc`}>{locale === "en" ? sub?.name : sub?.nameBn}</li></NavLink>}
                                            </div>
                                        )}
                                    </div>}
                                </div>

                                <NavLink to={`/contact`} className="nav-NavLink hover:text-[#f1593a] duration-300 ">
                                    <li onClick={() => { setOpenSub(false); setOpen(false) }} className={`${styles.archivo} ${(pathname === '/contact' || pathname === '/bn/contact') ? 'active' : ''}`}>
                                        {locale === "en" ? "Contact" : "যোগাযোগ করুন"}
                                    </li>
                                </NavLink>
                            </ul>
                        </div>
                    </div>
                    <div className='hidden lg:flex items-center gap-2'>
                        {/* <LanguageChanger locale={locale} handleChangeTwo={handleChangeTwo}/> */}
                        <NavLink to="https://admin.ebitans.com/login">
                            <button className={`group relative overflow-hidden rounded bg-[#F1593A] w-[73px] h-[37px] shadow`}>
                                <div className={`absolute inset-0 w-0 bg-[#000] transition-all duration-[250ms] ease-out group-hover:w-full`}></div>
                                <span className={`${styles.archivo} relative text-white`}>Login</span>
                            </button>
                        </NavLink>
                    </div>
                </div>
            </header >

            {/* mobile view menu  */}
            <div className={`fixed top-0 ${openMenu ? "left-0" : "left-[100%]"} duration-500 w-full bottom-0 z-50 p-2 `}>
                <div className='flex flex-col gap-10 bg-[#F5A797] bg-opacity-25 border border-[#F1593A] rounded backdrop-blur-lg mx-auto h-full overflow-y-auto'>
                    <IoCloseCircleOutline onClick={() => setOpenMenu(!openMenu)} className='text-[#F1593A] absolute right-2 top-2 text-3xl' />
                    <div className='mt-10 pl-4 '>
                        <div><NavLink to="/"><img width={500} height={500} src={images?.darkLogo} alt="ebitansLogo" className='lg:h-12 h-7 w-auto' /></NavLink></div>
                    </div>
                    <div className="pl-4 ">
                        <div className={``}>
                            <ul className='relative flex flex-col gap-y-8 group'>
                                <NavLink to='/' onClick={() => setOpenMenu(!openMenu)} className="nav-NavLink hover:text-[#f1593a] duration-300 w-max">
                                    <li className={`${styles.archivo} ${pathname === '/' ? 'active' : ''}`}>
                                        {locale === "en" ? "Home" : "হোম"}
                                    </li>
                                </NavLink>

                                <li onClick={() => setOpenSub(!openSub)} className={`${styles.archivo} ${openSub && "text-[#f1593a] border-b border-[#f1593a]"} group flex items-center gap-2 lg:cursor-pointer w-max`}>
                                    <span className={`${styles.archivo}`}>{locale === "en" ? "Solutions" : "সমাধান"}</span>
                                    <IoIosArrowDown className={` `} />
                                </li>

                                {openSub && <div className='flex flex-col gap-3 w-max pl-8'>
                                    {subMenu_solutions?.map((sub, id) =>
                                        <ul ref={dropdownRef} key={id}>
                                            <NavLink to={sub?.NavLink} onClick={() => setOpenMenu(!openMenu)}><li className={`${styles.archivo} list-disc`}>{locale === "en" ? sub?.name : sub?.nameBn}</li></NavLink>
                                        </ul>
                                    )}
                                </div>}

                                <NavLink to='/pricing' onClick={() => setOpenMenu(!openMenu)} className="nav-NavLink hover:text-[#f1593a] duration-300 w-max">
                                    <li className={`${styles.archivo} `}>
                                        {locale === "en" ? "Pricing" : "মূল্য তালিকা"}
                                    </li>
                                </NavLink>

                                <li onClick={() => { setOpenSub(false); setOpen(!open) }} className={`${styles.archivo} ${open && "text-[#f1593a] border-b border-[#f1593a]"} group flex items-center gap-2 lg:cursor-pointer w-max`}>
                                    <span className={`${styles.archivo}`}>{locale === "en" ? "Resources" : "রিসোর্সেস"}</span>
                                    <IoIosArrowDown className={` `} />
                                </li>

                                {open && <div className='flex flex-col gap-3 w-max pl-8'>
                                    {subMenu_resource?.map((sub, id) =>
                                        <ul key={id}>
                                            {sub?.name === "Video" ? <NavLink to={'https://www.youtube.com/@ebitans/videos'} target="_blank"><li className={`${styles.archivo} list-disc`}>{sub?.name}</li></NavLink> : <NavLink to={sub?.NavLink} onClick={() => setOpenMenu(!openMenu)}><li className={`${styles.archivo} list-disc`}>{locale === "en" ? sub?.name : sub?.nameBn}</li></NavLink>}
                                        </ul>
                                    )}
                                </div>}

                                <NavLink to='/contact' onClick={() => setOpenMenu(!openMenu)} className="nav-NavLink hover:text-[#f1593a] duration-300 w-max">
                                    <li className={`${styles.archivo} `}>
                                        {locale === "en" ? "Contact" : "যোগাযোগ করুন"}
                                    </li>
                                </NavLink>

                                <NavLink to={'/product-search-engine' }onClick={() => setOpenMenu(!openMenu)} className="bg-white border text-[#f1593a] border-[#f1593a] px-4 w-max h-[37px] duration-300 flex justify-center items-center">
                                    <li className={`${styles.archivo} `}>
                                        Product খুঁজো 
                                    </li>
                                </NavLink>
                            </ul>
                        </div>
                    </div>

                    <div className='h-full w-full flex justify-center items-end pb-10'>
                        <div className='flex items-center gap-2'>
                            {/* <LanguageChanger locale={locale} /> */}
                            <NavLink to="https://admin.ebitans.com/login">
                                <button className={`group relative overflow-hidden rounded bg-[${ebitansColor}] w-[73px] h-[37px] shadow`}>
                                    <div className={`absolute inset-0 w-0 bg-[#000] transition-all duration-[250ms] ease-out group-hover:w-full`}></div>
                                    <span className={`${styles.archivo} relative text-white`}>Login</span>
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Header

const subMenu_resource = [

    {
        name: "eBitans Learning",
        nameBn: "নির্দেশনা",
        NavLink: "/instruction"
    },
    {
        name: "Video",
        nameBn: "ভিডিও",
        NavLink: "https://www.youtube.com/@ebitans/videos"
    },
    {
        name: "Blog",
        nameBn: "ব্লগ",
        NavLink: "blogs"
    },
    {
        name: "FAQ",
        nameBn: "জিজ্ঞাসা",
        NavLink: "/faq"
    },
]

const subMenu_solutions = [

    {
        name: "Product খুঁজো",
        nameBn: "Product খুঁজো",
        NavLink: "/product-search-engine"
    },
    {
        name: "Themes",
        nameBn: "থিম",
        NavLink: "/all-theme"
    },

]
import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import logo from "../../assets/img/logo-dark.png";
import ScrollToTop from "../components/scrollToTop/ScrollToTop";
import { AOSInit } from "./common/ConvertDesignFromNext/aos";
import Footer from "./common/ConvertFromNext/Footer/Footer";
import Header from "./common/ConvertFromNext/Header/Header";

const Main = React.lazy(() => import("./common/Main"));

const Layout = () => {
  const url = window.location.href;
  const urlAll = [
    "http://localhost:3000/visitingcard/",
    "http://localhost:3000/visitingcard/hasoyad",
    "http://localhost:3000/visitingcard/hpdeepa",
    "https://ebitans.com/visitingcard/",
    "https://ebitans.com/visitingcard/hasoyad",
    "https://ebitans.com/visitingcard/hpdeepa",
    "https://ebitans.com/visitingcard/rayhansheikh",
    "https://ebitans.com/visitingcard/sara",
    "https://ebitans.com/visitingcard/arafat",
    "https://ebitans.com/visitingcard/ashiqurrahman",
    "http://localhost:3000/visitingcard/rayhansheikh",
    "http://localhost:3000/homeui",
  ];

  const pseUrl = ["product-search-engine", "category"];

  const visiting = urlAll?.includes(url);

  const location = useLocation();
  const path = location?.pathname;
  const segments = path.split("/");
  const searchTerm = segments[1];

  const pseActiveUrl = pseUrl?.includes(searchTerm);

  const isHomePage = path === "/";

  return (
    <>
      <ToastContainer position="top-right" newestOnTop />
      <AOSInit />
      <header
        className={`${
          visiting || pseActiveUrl ? "hidden" : "fixed top-0 z-50"
        }`}
      >
        <Header />
      </header>

      {isHomePage && (
        <div className="absolute top-0 left-0 bottom-0 h-full w-full lg:grid grid-cols-6 divide-x hidden">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}

      <ScrollToTop />

      <Suspense
        fallback={
          <div className="flex justify-center h-screen items-center">
            <img src={logo} className="h-10" alt="EbitansLogo" />
          </div>
        }
      >
        <main>
          <Main />
        </main>
        <footer className={`${visiting ? "hidden" : "block"}`}>
          <Footer />
        </footer>
      </Suspense>
    </>
  );
};

export default Layout;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import useTheme from "../hooks/useTheme";
import { imgUrl } from "../siteSettings/siteUrl";

const Footer = React.lazy(() => import("./common/footer/Footer"));
const Header = React.lazy(() => import("./common/header/Header"));
const Main = React.lazy(() => import("./common/main/Main"));
const CartPopUp = React.lazy(() => import("./pages/CartPopUp"));
const MobileBottomMenu = React.lazy(() => import("./pages/MobileBottomMenu"));

const Layout = () => {
  const { headerSetting, design, store_id } = useTheme();
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.href = headerSetting?.favicon
      ? imgUrl + headerSetting?.favicon
      : imgUrl + headerSetting?.logo;
    // document.title = headerSetting?.website_name;
  }, [
    headerSetting?.logo,
    headerSetting?.website_name,
    store_id,
    headerSetting?.favicon,
  ]);

  return (
    <div
      className={`${
        design?.template_id === "34" ? "bg-thirty-one" : "bg-white"
      }`}
    >
      <ToastContainer position="top-right" newestOnTop />
      <Helmet>
        <title>{headerSetting?.website_name}</title>
        <meta
          name="description"
          content={`${headerSetting?.short_description}`}
        />
        <meta name="keywords" content="online, e-commerce" />
        <meta property="og:title" content={`${headerSetting?.website_name}`} />
        <meta
          property="og:description"
          content={`${headerSetting?.short_description}`}
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <Main />
      </main>
      <footer>
        <Footer />
      </footer>
      <CartPopUp />
      <MobileBottomMenu />
    </div>
  );
};

export default Layout;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import productService from "../../services/product.service";
const user = JSON.parse(localStorage.getItem("user"));

export const single_product = createAsyncThunk(
  "product/details",
  async ({ store_id, product_id }, thunkAPI) => {
    try {
      const response = await productService.product_details(
        store_id,
        product_id
      );

      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = user
  ? { isLoggedIn: true, user, success: "" }
  : { isLoggedIn: false, user: null, success: "" };
const productSlice = createSlice({
  name: "product",
  initialState,
  extraReducers: {},
});
const { reducer } = productSlice;
export default reducer;

export const PageView = () => {
  window.fbq("track", "PageView");
};

export const Purchase = (value, currency) => {
  window.fbq("track", "Purchase", {
    value,
    currency,
  });
};

export const AddToCart = (content_ids, content_type) => {
  window.fbq("track", "AddToCart", {
    content_ids,
    content_type,
  });
};

export const ViewContent = (
  content_ids,
  content_type,
  content_name,
  content_category,
  value
) => {
  window.fbq("track", "ViewContent", {
    content_ids,
    content_type,
    content_name,
    content_category,
    value,
    currency: "BDT",
  });
};
